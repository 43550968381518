import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from '../../components/project_page/ProjectPageWrapper';
import TextBlock from '../../components/project_page/TextBlock';
import Mobile3 from '../../components/project_page/Mobile3';
import QuoteBlock from '../../components/project_page/QuoteBlock';
import Web1 from '../../components/project_page/Web1';
import Image1 from '../../components/project_page/Image1';
import ImageGrid from '../../components/project_page/ImageGrid';
import AnimatedSave from '../../images/projects/bookleague/saved.gif'
import globalStyles from '../global.module.css';

const headerBackground = "#A3EEEE"
const navColor="#222";
const Bookleague = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Book League"
      oneLiner="The first social reading tracker"
      link="https://bookleague.app"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="The Collaboration"
          headline={`The most fun way to build a reading habit`}
          content={<><p>Long Weekend worked with Book League to design and develop a
            mobile app that makes it easy to start and stick with a daily reading habit.</p>
            <p>
              On Book League, readers can track how much they read, cheer on their friends'
              progress and join reading groups. Whether someone is motivated by comraderie
              or competition, we built this app to help them achieve their reading goals.
            </p>
          </>}
        />
      </div>
      <div className={`${globalStyles.marginBottomBig}`}>
        <Mobile3
          one={<Img fluid={data.body1.childImageSharp.fluid} />}
          two={<Img fluid={data.body2.childImageSharp.fluid} />}
          three={<Img fluid={data.body3.childImageSharp.fluid} />}
        />
      </div>
      <div className={`${globalStyles.flex} ${globalStyles.justifyCenter} ${globalStyles.marginBottomNormal}`}>

        <QuoteBlock title={"Project Feedback"} headline={'The team at Long Weekend do an amazing job of staying true to your vision while also coming to the table with ground-breaking ideas'} content={'On top of being top notch developers and designers, the team at Long Weekend do an amazing job of staying true to your vision while also coming to the table with ground-breaking ideas. After working with them, I couldn’t imagine bringing my dream and vision of Book League to life with any other company. I couldn’t be happier with the results.'} />
      </div>
      <div className={`${globalStyles.marginBottomHuge}`}>
        <Web1
          desktopImage={<Img fluid={data.body5.childImageSharp.fluid} />}
          mobileImage={<Img fluid={data.body5.childImageSharp.fluid} />}
        />
      </div>

      <div className={`${globalStyles.marginBottomBig}`}>
        <div className={`${globalStyles.flex} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}>
          <div className={`${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}>
            <TextBlock
              title="Engineering"
              headline={`Google Books integration`}
              content={`Book League integrates with Google's Books API so users can instantly
              select the books they want to read.`
              }
            />
          </div>
          <div className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}>
            <Image1 maxWidth={420} img={<Img fluid={data.body4.childImageSharp.fluid} />} />
          </div>
        </div>
      </div>

      <div className={`${globalStyles.marginBottomHuge}`}>
        <div className={`${globalStyles.flex} ${globalStyles.flexReverse} ${globalStyles.flexCenter} ${globalStyles.mobileFlexColumnReverse}`}>
          <div className={`${globalStyles.marginRightBig} ${globalStyles.marginRightDesktopOnly}`}>
            <TextBlock
              title="Product Design"
              headline={`Celebrate Reading`}
              content={
                <>
                <p>
                  Each time a Book League member finishes reading is cause for celebration.
                </p>
                <p>
                  In this moment, users are also given an inspiring quote and an opportunity
                  to share the app.
                </p>
                <p>
                  We anticipate making this moment both special and actionable will encourage
                  users to keep reading and share the app with friends.
                </p>
                </>
              }
            />
          </div>
          <div className={`${globalStyles.width100} ${globalStyles.marginBottomMobileOnly}`}>
            <Image1 maxWidth={320} img={<img src={AnimatedSave} width='100%' style={{ borderRadius: 40, overflow: 'hidden' }} />} />
          </div>
        </div>
      </div>
      <Image1 maxWidth={'600px'} img={<Img fluid={data.body6.childImageSharp.fluid} />} />
    </ProjectPageWrapper>
  </Layout>
)

export default Bookleague

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/bookleague/header.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    mobileHeader: file(relativePath: { eq: "projects/bookleague/header-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body1: file(relativePath: { eq: "projects/bookleague/phone1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body2: file(relativePath: { eq: "projects/bookleague/phone2.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body3: file(relativePath: { eq: "projects/bookleague/phone3.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body4: file(relativePath: { eq: "projects/bookleague/phone4.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    body5: file(relativePath: { eq: "projects/bookleague/site1.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    body6: file(relativePath: { eq: "projects/bookleague/logo.png" }) {
      childImageSharp {
        fluid(maxWidth:2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
