import React from "react"
import styles from './QuoteBlock.module.css';

const QuoteBlock = ({
  content,
  headline,
  title
}) => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      <div className={`${styles.quote} ${styles.quoteLeft}`}>
        {'"'}
      </div>
      <div>
        <h3 className={styles.title}>{title}</h3>
        <h2 className={styles.headline}>{headline}</h2>
        <div className={styles.content}>{content}</div>
      </div>
      <div className={`${styles.quote} ${styles.quoteRight}`}>
        {'"'}

      </div>
    </div>
  </div>
)

export default QuoteBlock
